import PropTypes from 'prop-types';
import { useState } from 'react';
import {useNavigate} from 'react-router-dom'

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText, Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';

// assets
// import { Card, Edit2, Logout, Profile, Profile2User } from 'iconsax-react';
import { Card, Edit2, Logout } from 'iconsax-react';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout }) => {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if(index === 4){
      navigate("/profile/change-password");
    }else if(index === 0){
      navigate("/profile");
    }
  };

  const handleLogoutClick = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmLogout = () => {
    handleLogout();
    setOpenConfirmDialog(false);
  };

  return (
    <>
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)}>
        <ListItemIcon>
          <Edit2 variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="Edit Profile" />
      </ListItemButton>
      {/* <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
        <ListItemIcon>
          <Profile variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="View Profile" />
      </ListItemButton> */}

      {/* <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
        <ListItemIcon>
          <Profile2User variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="Social Profile" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 4)}>
        <ListItemIcon>
          <Card variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="Billing" />
      </ListItemButton> */}
      <ListItemButton selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 4)}>
        <ListItemIcon>
          <Card variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 2} onClick={handleLogoutClick}>
        <ListItemIcon>
          <Logout variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
    <AlertRequestConfirm
    open={openConfirmDialog}
    handleOpen={setOpenConfirmDialog}
    onConfirm={handleConfirmLogout}
    message="Are you sure you want to logout?"
    confirmButtonText="Logout"
    confirmButtonColor="error"
    icon={<Logout variant="Bold" size={24} />}
  />
  </>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func
};
// ==============================|| CONFIRM LOGOUT BOX ||============================== //

const AlertRequestConfirm = ({ open, handleOpen, onConfirm, message, icon, confirmButtonText, confirmButtonColor }) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleOpen(false)}
      keepMounted
      maxWidth="xs"
      aria-labelledby="alert-confirm-title"
      aria-describedby="alert-confirm-description"
    >
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={3.5}>
          <Stack alignItems="center" sx={{ width: 72, height: 72, fontSize: '1.75rem' }}>
            {icon}
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h4" align="center">
              {message}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button fullWidth onClick={() => handleOpen(false)} color='secondary' variant="outlined">
              Cancel
            </Button>
            <Button
              fullWidth
              color={confirmButtonColor || 'primary'}
              variant="contained"
              onClick={() => {
                onConfirm();
                handleOpen(false);
              }}
              autoFocus
            >
              {confirmButtonText}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

AlertRequestConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  confirmButtonText: PropTypes.string,
  confirmButtonColor: PropTypes.string
};

export default ProfileTab;
