import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
// import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { REGISTER, LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';

// const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// const verifyToken = (serviceToken) => {
//   if (!serviceToken) {
//     return false;
//   }
//   const decoded = jwtDecode(serviceToken);

//   /**
//    * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
//    */
//   return decoded.exp > Date.now() / 1000;
// };
const verifyToken = (refreshToken) => {
  if (!refreshToken) {
    return false;
  }

  try {
    const decoded = jwtDecode(refreshToken);
    if (decoded.exp <= Date.now() / 1000) {
      return false;
    }
    return true;
  } catch (error) {
    console.error('Error decoding refresh token:', error);
    return false;
  }
};

const setSession = (serviceToken, refreshToken, vendorMobile, vendorCompany, vendorCity) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('vendorMobile', vendorMobile);
    localStorage.setItem('vendorCompany', vendorCompany);
    localStorage.setItem('vendorCity', vendorCity);
    // axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    axios.defaults.headers.common.Authorization = serviceToken;
    axios.defaults.headers.common.refreshToken = refreshToken;
  } else {
    localStorage.removeItem('serviceToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('vendorMobile');
    localStorage.removeItem('vendorCompany');
    localStorage.removeItem('vendorCity');
    delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common.refreshToken;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem('serviceToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const vendorMobile = localStorage.getItem('vendorCompany');
        const vendorCompany = localStorage.getItem('vendorMobile');
        const vendorCity = localStorage.getItem('vendorCity');
        if (refreshToken && verifyToken(refreshToken)) {
          setSession(serviceToken, refreshToken, vendorMobile, vendorCompany, vendorCity);
          // const response = await axios.get('/api/account/me');
          // const { user } = response.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true
              // user
            }
          });
        } else {
          await logout();
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (mobile, password) => {
    // const response = await axios.post('/api/account/login', { email, password });
    const response = await axios.post('/vendor/login', { mobile, password });
    // const { serviceToken, user } = response.data;
    const user = {};
    user.id = response.data.result.data.id;
    user.mobile = response.data.result.data.mobile;
    user.company = response.data.result.data.companyName;
    user.city = response.data.result.data.city;
    localStorage.setItem('vendorId', user.id);

    const serviceToken = response.data.result.data.token;
    const refreshToken = response.data.result.data.refreshToken;
    setSession(serviceToken, refreshToken, user.mobile, user.company, user.city);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });
  };

  const register = async (first_name, last_name, company_name, gst_no, product_info, email, mobile_no, city, pincode) => {
      const response = await axios.post('/vendor/register', {
        first_name,
        last_name,
        company_name,
        gst_no,
        product_info,
        email,
        mobile_no,
        city,
        pincode
      });
      let users = response.data;
      if (response) {
        dispatch({
          type: REGISTER,
          payload: {
            isRegistered: true
            // user
          }
        });
    }

  
      // Update the local storage if needed
      // if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      //   const localUsers = window.localStorage.getItem('users');
      //   users = [
      //     ...JSON.parse(localUsers),
      //     {
      //       id: chance.bb_pin(),
      //       email,
      //       password,
      //       name: `${firstName} ${lastName}`
      //     }
      //   ];
      // }
  
      window.localStorage.setItem('users', JSON.stringify(users));
  };
  

  // const logout = () => {
  //   setSession(null);
  //   dispatch({ type: LOGOUT });
  // };
  const logout = async () => {
    const vendorId = localStorage.getItem('vendorId');
    try {
      await axios.post('/vendor/logout', { id: vendorId });
    } catch (error) {
      console.error('Logout API error:', error);
    } finally {
      setSession(null);
      localStorage.removeItem("vendorId")
      dispatch({ type: LOGOUT });
    }
  };

  const resetPassword = async () => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
